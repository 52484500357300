<template>
    <div>
        <v-overlay :value="loading"></v-overlay>
        <v-row>
            <v-col class="d-none d-sm-flex">
                <v-toolbar-title>Benutzer</v-toolbar-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <validation-observer
                        ref="observer"
                >
                    <v-row>
                        <v-col cols="12" sm="6">
                            <zw-input name="email"
                                      rules="required"
                                      label="Benutzername"
                                      v-model="form.user.email"
                                      :disabled="!['VE', 'A'].includes(getMe().user.role)"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-input name="password"
                                      :rules="form.id ? '' : 'required'"
                                      label="Passwort"
                                      type="password"
                                      v-model="form.user.password"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-input name="personal_number"
                                      label="Personalnummer"
                                      v-model="form.personal_number"
                                      :disabled="!['VE', 'A'].includes(getMe().user.role)"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-input name="construction_zone_name"
                                      label="Stammkostenstelle"
                                      v-model="form.construction_zone_name"
                                      :disabled="!['VE', 'A'].includes(getMe().user.role)"
                            ></zw-input>
                        </v-col>
                        <v-col cols="6" xs="6">
                            <zw-input name="lastname"
                                      label="Name"
                                      rules="required"
                                      v-model="form.lastname"
                                      :disabled="!['VE', 'A'].includes(getMe().user.role)"
                            ></zw-input>
                        </v-col>
                        <v-col cols="6" xs="6">
                            <zw-input name="firstname"
                                      label="Vorname"
                                      rules="required"
                                      v-model="form.firstname"
                                      :disabled="!['VE', 'A'].includes(getMe().user.role)"
                            ></zw-input>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-select name="position"
                                       v-model="form.position"
                                       label="Mitarbeiterkennzeichen"
                                       :items="$root.$children[0].getConstant('positions')"
                                       :disabled="!['VE', 'A'].includes(getMe().user.role)"
                            ></zw-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <zw-radio-group name="activity_kz"
                                            v-model="form.activity_kz"
                                            label="Tätigkeitskennnzeichen"
                                            :items="$root.$children[0].getOptionsWithValue('tkz', form.activity_kz)"
                                            item-text="label"
                                            item-value="id"
                                            :disabled="!['VE', 'A'].includes(getMe().user.role)"
                            ></zw-radio-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="$vuetify.breakpoint.smAndUp">
                        <v-col class="text-right">
                            <v-btn
                                    color="primary"
                                    class="white--text"
                                    @click="save()"
                            >
                                Speichern
                            </v-btn>
                        </v-col>
                    </v-row>
                </validation-observer>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {updateEmployee} from './../graphql/employee'

    export default {
        data: () => ({
            loading: true,
            form: {
                user: {}
            },
            defaultForm: {
                personal_number: '',
                construction_zone_name: '',
                firstname: '',
                lastname: '',
                position: '',
                activity_kz: '1',
                user: {
                    email: '',
                    password: '',
                }
            }
        }),
        mounted: function () {
            this.$root.$children[0].bottomMenu = [
                {
                    icon: 'check',
                    event: () => {
                        this.save()
                    }
                }
            ]

            this.form = JSON.parse(JSON.stringify(this.$root.$children[0].me))

            delete this.form.__typename
            delete this.form.user.__typename

            this.loading = false
        },
        methods: {
            getMe() {
                return this.$root.$children[0].me;
            },
            save() {
                this.loading = true
                this.$refs.observer.validate().then(valid => {
                    if (valid) {
                        this.$apollo.mutate({
                            mutation: updateEmployee,
                            variables: {
                                id: this.form.id,
                                personal_number: this.form.personal_number,
                                construction_zone_name: this.form.construction_zone_name,
                                firstname: this.form.firstname,
                                lastname: this.form.lastname,
                                position: this.form.position,
                                activity_kz: this.form.activity_kz,
                                user: {
                                    email: this.form.user.email,
                                    password: this.form.user.password,
                                }
                            },
                        }).then((data) => {

                        }).catch((error) => {
                            let {graphQLErrors} = error;
                            if (graphQLErrors[0].extensions.validation) {
                                this.$refs.observer.setErrors(graphQLErrors[0].extensions.validation)
                            }
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>